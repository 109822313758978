@import "mixins/font-face";

@include font-face('Circe', '../fonts/Circe-Thin',        100);
@include font-face('Circe', '../fonts/Circe-ExtraLight',  200);
@include font-face('Circe', '../fonts/Circe-Light',       300);
@include font-face('Circe', '../fonts/Circe-Regular',     400);
@include font-face('Circe', '../fonts/Circe-Bold',        700);
@include font-face('Circe', '../fonts/Circe-ExtraBold',   800);

// @include font-face('Circe', '../fonts/Circe-ThinItalic',        100, italic);
// @include font-face('Circe', '../fonts/Circe-ExtraLightItalic',  200, italic);
// @include font-face('Circe', '../fonts/Circe-LightItalic',       300, italic);
// @include font-face('Circe', '../fonts/Circe-RegularItalic',     400, italic);
// @include font-face('Circe', '../fonts/Circe-MediumItalic',      500, italic);
// @include font-face('Circe', '../fonts/Circe-SemiBoldItalic',    600, italic);
// @include font-face('Circe', '../fonts/Circe-BoldItalic',        700, italic);
// @include font-face('Circe', '../fonts/Circe-ExtraBoldItalic',   800, italic);
// @include font-face('Circe', '../fonts/Circe-BlackItalic',       900, italic);