.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 20px;
    background-color: rgba(174, 194, 198, .6);
    z-index: 999;
    transition: all .3s ease;
    overflow: scroll;

    &-wrapper {
        padding-top: 160px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        max-width: 460px;
        width: 100%;
        // max-height: 100vh;
        margin: 0 auto;
        background-color: #fff;
        transition: all .3s ease;
    }

    &-close {
        position: absolute;
        top: 50%;
        right: 30px;
        height: 100%;
        padding: 0;
        font-size: 40px;
        line-height: 1;
        color: #c6d5d8;
        background: transparent;
        border: none;
        transform: translateY(-50%);
    }

    &-head {
        position: relative;
        margin: 20px 0;
        padding: 0 30px;
        font-size: 30px;
    }

    &-body {
        padding: 0 30px;
    }

    &-title {
        padding: 10px 0;
        border-bottom: 2px solid #c6d5d8;
    }

    &-radios-wrapper {
        margin: 20px 0 30px;

        &.account-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &-confirmation {
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 600;

        span {
            color: #ffab00;
        }
    }

    &-custom-radio {
        cursor: pointer;

        input[type=radio] {
            display: none;

            &:checked + span::before {
                background-color: #f8aa10;
            }
        }

        span {
            position: relative;
            padding-left: 20px;
            font-size: 20px;
            line-height: 1; 

            &::before {
                content: '';
                position: absolute;
                top: 45%;
                left: 0;
                width: 10px;
                height: 10px;
                background-color: #b2c2c6;
                border: 1px solid #d3edf1;
                border-radius: 50%;
                transform: translateY(-50%);
                box-sizing: border-box;
            }
        }

        &:hover {
            span::before {
                background-color: #f8aa10;
            }
        }
    }

    .recover-pwd {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: 600;
        color: #73bfc8;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: #ffab00;
        }
    }

    .btn {
        font-size: 20px;
        margin-bottom: 25px;
        padding: 20px;
    }

    &-footer {
        padding: 20px 30px;
        color: #9a9a9a;
        text-align: center;
        background-color: #daf4f8;

        span {
            display: inline-block;
            color: #000;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            cursor: pointer;
        }
    }
}


.vue-modal-enter {
    opacity: 0;
}

.vue-modal-leave-active {
    opacity: 0;
}

.vue-modal-enter .modal-container,
.vue-modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}