/**
 * @package PickMeUp
 * @author  Nazar Mokrynskyi <nazar@mokrynskyi.com>
 * @license 0BSD
 */
$border-radius                    : .4em !default;
$background                       : #fff !default;
$color                            : #666 !default;
$background-hover                 : transparent !default;
$color-hover                      : #ffad00 !default;
$nav-color                        : $color !default;
$nav-color-hover                  : $color-hover !default;
$not-in-month                     : #eee !default;
$not-in-month-hover               : #333 !default;
$disabled                         : #999 !default;
$selected                         : #fff !default;
$selected-background              : #ffad00 !default;
$not-in-month-selected-background : #73bfc8 !default;
$day-of-week                      : $not-in-month-hover !default;
$today-background                 : $not-in-month-selected-background !default;
$today-color                      : #fff !default;

.pickmeup {
	background      : $background;
	border 			: 1px solid #b7ebef;
	border-radius   : $border-radius;
	-moz-box-sizing : content-box;
	box-sizing      : content-box;
	display         : inline-block;
	position        : absolute;
	touch-action    : manipulation;
	z-index 		: 1;

	* {
		-moz-box-sizing : border-box;
		box-sizing      : border-box;
	}

	&.pmu-flat {
		position : relative;
	}

	&.pmu-hidden {
		display : none;
	}

	.pmu-instance {
		display    : inline-block;
		height     : 13.8em;
		padding    : .5em;
		text-align : center;
		width      : 15em;

		.pmu-button {
			color           : $color;
			cursor          : pointer;
			outline         : none;
			text-decoration : none;
		}

		.pmu-today {
			background : $today-background;
			color      : $today-color;
		}

		.pmu-button:hover {
			background : $background-hover;
			color      : $color-hover;
		}

		.pmu-not-in-month {
			color : $not-in-month;
		}

		.pmu-disabled,
		.pmu-disabled:hover {
			color  : $disabled;
			cursor : default;
		}

		.pmu-selected {
			background : $selected-background;
			color      : $selected;
		}

		.pmu-not-in-month.pmu-selected {
			background : $not-in-month-selected-background;
		}

		nav {
			color       : $nav-color;
			display     : -ms-flexbox;
			display     : -webkit-flex;
			display     : flex;
			line-height : 2em;

			*:first-child :hover {
				color : $nav-color-hover;
			}

			.pmu-prev,
			.pmu-next {
				display : none;
				height  : 2em;
				width   : 1em;
			}
			.pmu-month {
				width : 14em;
			}
		}

		.pmu-years,
		.pmu-months {
			* {
				display     : inline-block;
				line-height : 3.6em;
				width       : 3.5em;
			}
		}

		.pmu-day-of-week {
			color  : $day-of-week;
			cursor : default;
		}

		.pmu-day-of-week,
		.pmu-days {
			* {
				display     : inline-block;
				line-height : 1.5em;
				width       : 2em;
			}
		}

		.pmu-day-of-week * {
			line-height : 1.8em;
		}
	}

	.pmu-instance:first-child .pmu-prev,
	.pmu-instance:last-child .pmu-next {
		display : block;
	}

	.pmu-instance:first-child,
	.pmu-instance:last-child {
		.pmu-month {
			width : 13em;
		}
	}

	.pmu-instance:first-child:last-child .pmu-month {
		width : 12em;
	}

	&:not(.pmu-view-days) .pmu-days,
	&:not(.pmu-view-days) .pmu-day-of-week,
	&:not(.pmu-view-months) .pmu-months,
	&:not(.pmu-view-years) .pmu-years {
		display : none;
	}
}
