.img-screen {
    @extend %container;

    &__row {
        @include grid-row();
        padding: 100px 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include respond-to('md') {
            padding: 50px 0;
            background-position: left;
        }
    }

    &__content {
        @include grid-col(12 null 8 null 5);
        @include grid-move(null null null null 1);
    }
}

.volunteers {
    .img-screen__row {
        background-image: url(../img/volunteers-bg.jpg);
    }
}

.citizens {
    .img-screen__row {
        background-image: url(../img/citizens-bg.jpg);
    }
}

.nco {
    .img-screen__row {
        background-image: url(../img/nco-bg.jpg);
    }
}

