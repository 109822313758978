.mmenu {
    position: fixed;
    display: none;
    flex-direction: column;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    margin: 0;
    padding: 0 0 50px 20px;
    font-size: 18px;
    background-color: #fff;
    z-index: 99999;
    transform: translateX(400px);
    transition: all .2s ease;
    overflow: auto;

    @include respond-to('sm') {
        width: 100%;
        padding: 0 20px 50px;
    }

    &-overlay {
        position: fixed;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.6);
        z-index: 999;
        cursor: pointer;
    }

    &-title {
        margin-bottom: 30px;
        font-size: 80px;
        line-height: 0.8;
        font-weight: 100;
        color: #d7d7d7;
        text-transform: uppercase;

        @include respond-to('sm') {
            font-size: 70px;
        }
    }

    &-controls {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    &-button {
        width: 100%;
        margin: 5px 0 5px -20px;
        padding: 15px 20px 15px 40px;
        color: #000;
        text-align: left;
        text-decoration: none;
        background-color: transparent;
        border: none;
        transition: all .2s ease;

        &:hover {
            color: #fff;
            background-color: #73bfc8;
        }

        @include respond-to('sm') {
            margin: 5px 0;
            border: 1px solid #73bfc8;
        }
    }

    &-close {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 50px;
        line-height: 0.8;
        color: #d7d7d7;
        background-color: transparent;
        border: none;
    }

    // &-copyright {
        // position: absolute;
        // left: 20px;
        // bottom: 50px;
    // }

    &-burger {
        display: none;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        font-size: 40px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @include respond-to('lg') {
            display: flex;
        }

        img {
            max-width: 100%;
        }
    }

    &-translate {
        transition: all .2s ease;
    }

    .site-search {
        display: none;

        @include respond-to('sm') {
            display: flex;
            margin-bottom: 20px;
        }
    }
}

body.blocked {
    overflow: hidden;
}