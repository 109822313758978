.socials {
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 300;
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background: linear-gradient(122deg, #c1d5d8 0%, #a1c5ca 100%);
        border-radius: 50%;
        transition: background .2s ease;

        &:hover {
            background: linear-gradient(122deg, #ffce2b 0%, #ffab00 100%);
        }

        img {
            max-width: 65%;
        }
    }
}