$breakpoints: (
  'xs': (max-width: 320px),
  'sm': (max-width: 575.98px),
  'md': (max-width: 767.98px),
  'lg': (max-width: 991.98px),
  'sxl': (max-width: 1023.98px),
  'xl': (max-width: 1199.98px),
  'xxl': (max-width: 1599.98px)
);

$mainBlueColor: #a6c7cc;