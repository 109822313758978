.site-search {
    display: flex;
    align-items: center;

    @include respond-to('sm') {
        display: none;
    }

    &__wrapper, &__input {
        width: 100%;
    }

    &__wrapper {
        position: relative;
        display: inline-block;

        &::before {
            @extend %gradient-border-bottom;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 9px;
            width: 17px;
            height: 17px;
            background: url(../img/icons/magnifier.svg) no-repeat center;
            background-size: contain;
            transform: translateY(-50%);
        }
    }

    &__input {
        padding: 10px;
        font-size: 13px;
        color: #000;
        background-color: transparent;
        border: none;

        &::-moz-placeholder {
            color: #000; /* Цвет подсказывающего текста */
        }

        &::-webkit-input-placeholder { 
            color: #000; 
        }

        &:-ms-input-placeholder { 
            color: #000; 
        }

        &::-ms-input-placeholder { 
            color: #000; 
        }

        &::placeholder { 
            color: #000; 
        }

        &:focus {
            outline: none;
        }

        &--mmenu {
            font-size: 18px;
        }
    }
}