$grid: (
  breakpoints: (
    xs: 0px,
    sm: 525px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  ),
  columns: 12,
  gap: 15px,
  container: 15px,
  box-sizing: border-box,
  mode: flex
);