.about {
    padding: 45px 0;
    background-color: #f9fbfd;

    .section-title__wrapper {
        margin: 0 -15px 20px;
    }

    &__container {
        @extend %container;
    }

    &__row {
        @include grid-row();
    }

    &__main {
        @include grid-col(12 null null 8);
    }

    &__statistics {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;

        @include respond-to('md') {
            margin-bottom: 10px;
        }
    }

    &__numbers {
        width: 20%;

        @include respond-to('md') {
            width: 40%;
            margin-bottom: 20px;
        }

        .title {
            font-size: 45px;
            font-weight: 900;
            color: #b1cdd1;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSItNi43JSIgeTE9IjEyLjMlIiB4Mj0iMTA2LjclIiB5Mj0iODcuNyUiPjxzdG9wIHN0b3AtY29sb3I9IiNjMWQ1ZDgiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iI2ExYzVjYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
            background-image: -webkit-gradient(linear, -6.7% 12.3%, 106.7% 87.7%,color-stop(0, rgb(193, 213, 216)),color-stop(1, rgb(161, 197, 202)));
            background-image: -webkit-repeating-linear-gradient(328deg,rgb(193, 213, 216) 0%,rgb(161, 197, 202) 100%);
            background-image: repeating-linear-gradient(122deg,rgb(193, 213, 216) 0%,rgb(161, 197, 202) 100%);
            background-image: -ms-repeating-linear-gradient(328deg,rgb(193, 213, 216) 0%,rgb(161, 197, 202) 100%);
            
            @media \0screen\,screen\9 {
                .gradient {
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc1d5d8",endColorstr="#ffa1c5ca",GradientType=1);
                }
            }
            background-clip: text;
            -webkit-text-fill-color: transparent;

            @include respond-to('xl') {
                font-size: 40px;
            }
        }

        .description {
            font-size: 16px;
        }
    }

    &__text {
        margin-bottom: 30px;
    }

    &__img {
        @include grid-col(4);
        background: url(../img/main-about-bg.png) no-repeat;
        background-position: center top;
        background-size: contain;
    }
}