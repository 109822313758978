@import "components/menu";
@import "components/auth";
@import "components/site-search";
@import "components/buttons";
@import "components/infocard";
@import "components/slider-controls";
@import "components/articles-nav";
@import "components/article-preview";
@import "components/map";
@import "components/mmenu";
@import "components/form";
@import "components/about";
@import "components/socials";
@import "components/img-screen";
@import "components/modals";

.pagination {
    width: 100%;
    
    &__wrapper {
        display: flex;
        justify-content: center;
        padding: 0;
    }

    &__button {
        width: 30px;
        height: 30px;
        margin: 0 -2px;
        list-style-type: none;
        font-size: 24px;
        line-height: 1.2;
        border-radius: 50%;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            padding: 2px 0 0;
            color: #000;
            text-decoration: none !important;
        }
        
        &--active, &:hover {
            background-color: #73bfc8;

            a {
                color: #fff;
            }
        }

        &--gap {
            padding: 1px 6px;
            cursor: default;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.sort-type {
    font-size: 15px;

    @include respond-to('sm') {
        display: block;
        margin-bottom: 10px;
    }

    %active-span {
        color: #ffad00;
        text-decoration: underline;
        cursor: pointer;
    }

    input[type=radio] {
        display: none;

        &:checked + span {
            @extend %active-span;
        }
    }

    span:hover {
        @extend %active-span;
    }
}