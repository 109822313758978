/// Управление отзывчивостью.
/// @access public
/// @param {String} $breakpoint - точка останова
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
                    type-of($raw-query) == 'string',
                    unquote($raw-query),
                    inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'Не указано значение для `#{$breakpoint}`. '
       + 'Пожалуйста, убедитесь, что точка останова объявлена в карте `$breakpoints`.';
  }
}