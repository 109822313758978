.infocard {

    @include respond-to('md') {
        margin: 10px 0;
    }
    
    &__container {
        @extend %container;
    }

    @include grid-col(12 12 4);

    &:hover {
        .infocard__wrapper {
            border-color: #8cdee2;
        }
    }

    &__row {
        @include grid-row();
        margin-bottom: 30px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 25px 30px;
        border: 1px solid #fff;
        box-shadow: 0px 0px 5px 0px $mainBlueColor;

        @include respond-to('xl') {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &__icon {
        width: 90px;
        height: 90px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include respond-to('xl') {
            width: 60px;
            height: 60px;
            margin-bottom: 15px;
        }
    }

    &__text {
        @include respond-to('xl') {
            text-align: center;
        }
    }

    @for $i from 1 through 3 {
        &--home:nth-child(#{$i}) {
            .infocard__icon {
                background-image: url(../img/icons/mission-#{$i}.svg);
            }

            &:hover {
                .infocard__icon {
                    background-image: url(../img/icons/mission-hover-#{$i}.svg);
                }
            }
        }
    }

    @for $i from 1 through 3 {
        &--volunteers:nth-child(#{$i}) {
            .infocard__icon {
                background-image: url(../img/icons/advantages-#{$i}.svg);
            }

            &:hover {
                .infocard__icon {
                    background-image: url(../img/icons/advantages-hover-#{$i}.svg);
                }
            }
        }
    }

    @for $i from 1 through 3 {
        &--citizens:nth-child(#{$i}) {
            .infocard__icon {
                background-image: url(../img/icons/citizens-#{$i}.svg);
            }

            &:hover {
                .infocard__icon {
                    background-image: url(../img/icons/citizens-hover-#{$i}.svg);
                }
            }
        }
    }

    @for $i from 1 through 3 {
        &--nco:nth-child(#{$i}) {
            .infocard__icon {
                background-image: url(../img/icons/nco-#{$i}.svg);
            }

            &:hover {
                .infocard__icon {
                    background-image: url(../img/icons/nco-hover-#{$i}.svg);
                }
            }
        }
    }

    &__title {
        margin-bottom: 15px;
        font-size: 14px;
    }

    &__description {
        width: 200px;
        font-size: 14px;

        @include respond-to('xl') {
            width: 100%;
        }
    }
}