.news {
    &__container {
        @extend %container;
    }

    &__row {
        @include grid-row();
    }

    &__articles-list {
        @include grid-col(12 null 9);
        @include grid-row();

        margin: 0;
        padding: 0;

        @include respond-to('md') {
            order: 1;
        }
    }

    .common-articles {
        @include grid-row();
    }

    &__nav {
        @include grid-col(12 null 3);
        padding-left: 45px;

        @include respond-to('xl') {
            padding-left: 15px;
        }

        @include respond-to('md') {
            order: 0;
        }

        
        .articles-nav__mobile {
            @include respond-to('md') {
                margin-bottom: 30px;
            }
        }

        .articles-nav__list {
            top: 50%;
        }
    }
}