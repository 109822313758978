.articles-nav {
    %nav-link {
        color: #ffad00;
        border-color: #ffad00;
    }

    @include grid-col(12 null 2);
    position: relative;

    &__list {
        margin: 0 0 20px;
        padding: 0;
        list-style-type: none;

        @include respond-to('md') {
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            padding: 10px 15px;
            background-color: #fff;
            opacity: 0;
            z-index: 0;
            transition: opacity .2s ease;

            &.active {
                opacity: 1;
                z-index: 9;
            }
        }
    }

    &__link, &__mobile {
        display: inline-block;
        padding: 15px 0 2px;
        padding-top: 15px;
        font-size: 18px;
        color: #000;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 1px dotted #000;
        cursor: pointer;
    }

    &__mobile {
        position: relative;
        display: none;

        @include respond-to('md') {
            display: inline-block;
        }

        &::after {
            content: '';
            position: absolute;
            right: -20px;
            width: 15px;
            height: 15px;
            background: url(../img/icons/chevron-bottom.svg) center no-repeat;
            background-size: contain;
        }
    }

    &__link:hover, &__link:active, &__link.active, &__mobile {
        @extend %nav-link;
    }
}