.news-article {
    &__container {
        @extend %container;
    }

    &__wrapper {
        @include grid-row();
    }

    .previews-line {
        @extend %container;
    }

    .article-preview__wrapper {
        padding: 0;
    }

    .section-title {
        font-size: 24px;
        font-weight: 500;

        &__wrapper {
            margin: 30px -15px;
        }
    }
}

.common-article {
    @include grid-col(12);
    @include respond-to('lg') {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    img {
        @include grid-col(12 null null 6);
        padding: 0 30px 0 0;
        float: left;

        @include respond-to('lg') {
            order: 0;
            width: 100%;
            padding: 0;
        }
    }

    p {
        margin-top: 20px;
    }

    ul, ol {
        padding-left: 20px;

        @include respond-to('md') {
            padding-left: 0;
        }
    }

    li {
        margin-top: 10px;
        padding-right: 20px;
        transform: translateX(20px);
        animation: fixlists 1s;
    }

    a {
        color: #ffad00;
        text-decoration: none;
    }

    .main-info {
        @include grid-col(12 null null 6);
        display: inline-block;
        padding: 0;
        
        @include respond-to('lg') {
            margin-bottom: 20px;
        }

        .title {
            margin: 20px 0 0;
            font-size: 22px;
            line-height: 0.9;
        }
    }
}

.category {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #a6c7cc;
}

@keyframes fixlists {
    0% {
      text-indent: -0.001em;
    }
    100% {
      text-indent: 0;
    }
  }