.map {
    position: relative;
    height: 770px;

    &--calendar {
        height: 570px;

        .map-form__container {
            display: flex;
            justify-content: flex-end;
        }
    }
    
    @include respond-to('sm') {
        height: auto;
    }

    &__cloud {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(../img/maps-cloud.png) no-repeat center;
        background-size: cover;
        pointer-events: none;
    }

    &-screen {
        height: 100%;

        @include respond-to('sm') {
            height: 750px;
        }
    }

    &--event {
        @extend %container;
        height: 500px;
        margin: 0 auto 40px !important;
    }

    //google maps styles override

    // .form-map .gm-style-pbt {
    //     @extend %container;
    //     margin: 0 auto;
    //     padding: 0 15px 0 380px;
    //     text-align: right;
    // }

    .gm-style .gm-style-iw {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        overflow: visible !important;

        @include respond-to('md') {
            min-width: 250px !important;
            min-height: 207px;
        }
      
        div {
          overflow: visible !important;
        }

        button {
            top: 12px !important;
            right: 16px !important;
            width: 15px !important;
            height: 15px !important;
            padding: 0 !important;
            font-size: 30px !important;
            line-height: 0.4;
            font-weight: 100;
            background: url(../img/icons/map/close.svg) no-repeat center !important;
            background-size: contain !important;

            img {
                display: none !important;
            }
        }
    }

    .gm-style .gm-style-iw-t::after {
        content: none;
    }

    .gm-style-iw-d {
        @include respond-to('md') {
            min-width: 250px !important;
            min-height: 207px;
        }
    }
}

.map-event {
    position: relative;
    max-width: 292px;
    padding: 25px 20px;
    font-size: 14px;
    border: 1px solid #bfd4d7;

    &::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: calc(50% - 7.5px);
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-bottom: 1px solid #bfd4d7;
        border-right: 1px solid #bfd4d7;
        transform: rotate(45deg);
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 0;
        font-size: 30px;
        line-height: 0.4;
        font-weight: 100;
        color: #bfd4d7;
        background-color: transparent;
        border: none;
    }

    &__heading {
        font-size: 15px;
        font-weight: 500;
        color: #ffad02;

        span {
            display: inline-block;
        }
    }

    &__organizer {
        margin-top: 15px;
        font-size: 12px;
    }

    &__organizer-name {
        margin-top: 5px;
        color: #73bfc8;
        text-transform: uppercase;
    }

    .section-contacts {
        flex-direction: column;
        padding: 0;

        p {
            margin: 5px 0;
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        width: 100%;
        padding-right: 25px;
        text-align: right;
        color: #ffad02;
        text-decoration: none;

        &:hover {
            color: #ffad02;
            text-decoration: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 20px;
            height: 10px;
            margin-top: -5px;
            background: url(../img/icons/map/arrow-gradient-right.svg) no-repeat center;
            background-size: contain;
        }
    }
}