.slider-controls {
    &__pagination {
        position: static;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        padding: 0 5px;
        font-size: 14px;
        color: $mainBlueColor;
    
        .swiper-pagination-current {
            align-self: flex-start;
            width: 21px;
            margin: 0 5px 5px 0;
            font-size: 18px;
            color: #000;
        }
    
        .swiper-pagination-total {
            align-self: flex-end;
            margin: 0 0 0 3px;
        }
    
        &-wrapper {
            position: relative;
            width: 80px;
        }
    }
    
    &__next, &__prev {
        top: 0;
        bottom: 0;
        margin: 0;
        background-size: contain;
    
        &:focus {
            outline: none;
        }
    }
    
    &__next {
        right: 0;
        width: 8px;
        height: 15px;
        background-image: url(../img/icons/main-slider-chevron-right.svg);
    }
    
    &__prev {
        left: 0;
        width: 8px;
        height: 15px;
        background-image: url(../img/icons/main-slider-chevron-left.svg);
    }
    
    &__scrollbar {
        @include grid-col(12);
        height: 1px;
        margin: 2px 0;
        background-color: $mainBlueColor;

        &.info__scrollbar {
            @include grid-col(12 null null null 10);
        }
    
        .swiper-scrollbar-drag {
            position: absolute;
            height: 3px;
            margin-top: -1px;
            background-color: inherit;
            cursor: pointer;
        }
    }
}