.menu {
    &__nav {
        @extend %container;
        
        @include respond-to('lg') {
            display: none;
        }

        &-row {
            @include grid-row();
            position: relative;
            justify-content: space-between;

            &::before {
                @extend %gradient-border-bottom;
            }
        }
    }

    &__link {
        padding: 22px 40px;
        font-size: 16px;
        color: #000;
        text-decoration: none;
        transition: color .1s ease-in;

        &:hover, &:active {
            color: #ffad00;
        }
    }
}

.topline {
    background-color: rgba(255, 255, 255, .3);

    &__container {
        @extend %container;
    }

    &__row {
        @include grid-row();
        padding: 30px 0;

        @include respond-to('lg') {
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__logo {
        @include grid-col(null null null 5);
        display: flex;
        color: #000;

        img {
            max-width: 56px;
            margin-right: 10px;
        }

        span {
            font-size: 22px;
            color: #000;

            @include respond-to('xl') {
                font-size: 18px;
            }
        }
    }

    &__main-link {
        display: inline-flex;
        align-items: center;
        width: 240px;
        margin-right: 15px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        @include respond-to('lg') {
            width: auto;
        }
    }

    &__enterprise {
        display: inline-flex;
        align-items: center;
        font-size: 12px;

        @include respond-to('lg') {
            display: none;
        }
    }

    &__auth {
        @include grid-col(2);
    }

    .site-search {
        @include grid-col(null null null 5);
        padding: 0 8.3333333%;

        @include respond-to('lg') {
            padding: 0;
        }
    }
}