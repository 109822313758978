.auth {
    position: relative;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;

    &:hover {
        color: #000;
        text-decoration: none;
    }

    @include respond-to('lg') {
        display: none;
    }

    &__right {
        width: 122.5px;

        @include respond-to('xl') {
            width: 77px;
        }
    }

    &__text {
        font-size: 11px;
        color: #000;
    }

    &__img {
        max-width: none;
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    &__upper {
        font-size: 12px;

        @include respond-to('xl') {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        @include respond-to('lg') {
            display: none;
        }
    }

    button {
        padding: 0;
        text-align: left;
        background: transparent;
        border: none;
        transition: all .1s ease-in;

        &:hover {
            font-weight: 600;
        }
    }

    &__sign-in {
        font-weight: 600;
    }

    span {
        @include respond-to('xl') {
            display: none;
        }
    }

    &__sign-up {
        width: 77px;
    }
}

.member {
    justify-content: space-between;

    &__portrait {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        background-image: url(../img/portrait-default.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &__name {
        max-width: 85px;
        max-height: 14px;
        font-weight: bold;
        overflow: hidden;
    }

    .auth__right {
        font-size: 14px;
    }
}