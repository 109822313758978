@charset "UTF-8";
@import url(../libs/normalize-css/normalize.css);
@import url(../libs/swiper/dist/css/swiper.css);
/**
 * @package PickMeUp
 * @author  Nazar Mokrynskyi <nazar@mokrynskyi.com>
 * @license 0BSD
 */
.pickmeup {
  background: #fff;
  border: 1px solid #b7ebef;
  border-radius: 0.4em;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: inline-block;
  position: absolute;
  touch-action: manipulation;
  z-index: 1;
}

.pickmeup * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pickmeup.pmu-flat {
  position: relative;
}

.pickmeup.pmu-hidden {
  display: none;
}

.pickmeup .pmu-instance {
  display: inline-block;
  height: 13.8em;
  padding: .5em;
  text-align: center;
  width: 15em;
}

.pickmeup .pmu-instance .pmu-button {
  color: #666;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.pickmeup .pmu-instance .pmu-today {
  background: #73bfc8;
  color: #fff;
}

.pickmeup .pmu-instance .pmu-button:hover {
  background: transparent;
  color: #ffad00;
}

.pickmeup .pmu-instance .pmu-not-in-month {
  color: #eee;
}

.pickmeup .pmu-instance .pmu-disabled,
.pickmeup .pmu-instance .pmu-disabled:hover {
  color: #999;
  cursor: default;
}

.pickmeup .pmu-instance .pmu-selected {
  background: #ffad00;
  color: #fff;
}

.pickmeup .pmu-instance .pmu-not-in-month.pmu-selected {
  background: #73bfc8;
}

.pickmeup .pmu-instance nav {
  color: #666;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  line-height: 2em;
}

.pickmeup .pmu-instance nav *:first-child :hover {
  color: #ffad00;
}

.pickmeup .pmu-instance nav .pmu-prev,
.pickmeup .pmu-instance nav .pmu-next {
  display: none;
  height: 2em;
  width: 1em;
}

.pickmeup .pmu-instance nav .pmu-month {
  width: 14em;
}

.pickmeup .pmu-instance .pmu-years *,
.pickmeup .pmu-instance .pmu-months * {
  display: inline-block;
  line-height: 3.6em;
  width: 3.5em;
}

.pickmeup .pmu-instance .pmu-day-of-week {
  color: #333;
  cursor: default;
}

.pickmeup .pmu-instance .pmu-day-of-week *,
.pickmeup .pmu-instance .pmu-days * {
  display: inline-block;
  line-height: 1.5em;
  width: 2em;
}

.pickmeup .pmu-instance .pmu-day-of-week * {
  line-height: 1.8em;
}

.pickmeup .pmu-instance:first-child .pmu-prev,
.pickmeup .pmu-instance:last-child .pmu-next {
  display: block;
}

.pickmeup .pmu-instance:first-child .pmu-month,
.pickmeup .pmu-instance:last-child .pmu-month {
  width: 13em;
}

.pickmeup .pmu-instance:first-child:last-child .pmu-month {
  width: 12em;
}

.pickmeup:not(.pmu-view-days) .pmu-days,
.pickmeup:not(.pmu-view-days) .pmu-day-of-week,
.pickmeup:not(.pmu-view-months) .pmu-months,
.pickmeup:not(.pmu-view-years) .pmu-years {
  display: none;
}

@font-face {
  font-family: "Circe";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Circe-Thin.eot");
  src: url("../fonts/Circe-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Thin.woff") format("woff"), url("../fonts/Circe-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Circe";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/Circe-ExtraLight.eot");
  src: url("../fonts/Circe-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraLight.woff") format("woff"), url("../fonts/Circe-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Circe";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Circe-Light.eot");
  src: url("../fonts/Circe-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Light.woff") format("woff"), url("../fonts/Circe-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Circe";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Circe-Regular.eot");
  src: url("../fonts/Circe-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Regular.woff") format("woff"), url("../fonts/Circe-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Circe";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Circe-Bold.eot");
  src: url("../fonts/Circe-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Bold.woff") format("woff"), url("../fonts/Circe-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Circe";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Circe-ExtraBold.eot");
  src: url("../fonts/Circe-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraBold.woff") format("woff"), url("../fonts/Circe-ExtraBold.ttf") format("truetype");
}

@media (max-width: 991.98px) {
  .menu__nav {
    display: none;
  }
}

.menu__nav-row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  justify-content: space-between;
}

.menu__link {
  padding: 22px 40px;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  transition: color .1s ease-in;
}

.menu__link:hover, .menu__link:active {
  color: #ffad00;
}

.topline {
  background-color: rgba(255, 255, 255, 0.3);
}

.topline__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 30px 0;
}

@media (max-width: 991.98px) {
  .topline__row {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.topline__logo {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  color: #000;
}

@media (min-width: 992px) {
  .topline__logo {
    width: 41.66667%;
  }
}

.topline__logo img {
  max-width: 56px;
  margin-right: 10px;
}

.topline__logo span {
  font-size: 22px;
  color: #000;
}

@media (max-width: 1199.98px) {
  .topline__logo span {
    font-size: 18px;
  }
}

.topline__main-link {
  display: inline-flex;
  align-items: center;
  width: 240px;
  margin-right: 15px;
  text-decoration: none;
}

.topline__main-link:hover {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .topline__main-link {
    width: auto;
  }
}

.topline__enterprise {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}

@media (max-width: 991.98px) {
  .topline__enterprise {
    display: none;
  }
}

.topline__auth {
  padding-left: 15px;
  padding-right: 15px;
  width: 16.66667%;
}

.topline .site-search {
  padding-left: 15px;
  padding-right: 15px;
  padding: 0 8.3333333%;
}

@media (min-width: 992px) {
  .topline .site-search {
    width: 41.66667%;
  }
}

@media (max-width: 991.98px) {
  .topline .site-search {
    padding: 0;
  }
}

.auth {
  position: relative;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
}

.auth:hover {
  color: #000;
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .auth {
    display: none;
  }
}

.auth__right {
  width: 122.5px;
}

@media (max-width: 1199.98px) {
  .auth__right {
    width: 77px;
  }
}

.auth__text {
  font-size: 11px;
  color: #000;
}

.auth__img {
  max-width: none;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.auth__upper {
  font-size: 12px;
}

@media (max-width: 1199.98px) {
  .auth__upper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 991.98px) {
  .auth__upper {
    display: none;
  }
}

.auth button {
  padding: 0;
  text-align: left;
  background: transparent;
  border: none;
  transition: all .1s ease-in;
}

.auth button:hover {
  font-weight: 600;
}

.auth__sign-in {
  font-weight: 600;
}

@media (max-width: 1199.98px) {
  .auth span {
    display: none;
  }
}

.auth__sign-up {
  width: 77px;
}

.member {
  justify-content: space-between;
}

.member__portrait {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-image: url(../img/portrait-default.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.member__name {
  max-width: 85px;
  max-height: 14px;
  font-weight: bold;
  overflow: hidden;
}

.member .auth__right {
  font-size: 14px;
}

.site-search {
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .site-search {
    display: none;
  }
}

.site-search__wrapper, .site-search__input {
  width: 100%;
}

.site-search__wrapper {
  position: relative;
  display: inline-block;
}

.site-search__wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 9px;
  width: 17px;
  height: 17px;
  background: url(../img/icons/magnifier.svg) no-repeat center;
  background-size: contain;
  transform: translateY(-50%);
}

.site-search__input {
  padding: 10px;
  font-size: 13px;
  color: #000;
  background-color: transparent;
  border: none;
}

.site-search__input::-moz-placeholder {
  color: #000;
  /* Цвет подсказывающего текста */
}

.site-search__input::-webkit-input-placeholder {
  color: #000;
}

.site-search__input:-ms-input-placeholder {
  color: #000;
}

.site-search__input::-ms-input-placeholder {
  color: #000;
}

.site-search__input::placeholder {
  color: #000;
}

.site-search__input:focus {
  outline: none;
}

.site-search__input--mmenu {
  font-size: 18px;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.btn {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  background-color: #73bfc8;
  background: linear-gradient(135deg, #8cdee2 0%, #73bfc8 100%);
  border: none;
}

.btn:hover {
  text-decoration: none;
}

.btn--yellow {
  background-color: #ffab00;
  background: linear-gradient(135deg, #ffce2b 0%, #ffab00 100%);
}

.btn--header-register {
  margin-right: 25px;
}

@media (max-width: 767.98px) {
  .btn--header-register {
    margin: 0 0 20px;
  }
}

.btn--news {
  width: 144px;
  margin-right: 30px;
}

@media (max-width: 767.98px) {
  .btn--news {
    width: 100%;
    margin: 0 0 20px;
  }
}

.btn--joint-action {
  padding: 15px;
}

.subscribe-btn {
  position: relative;
  padding-left: 42px;
  font-size: 14px;
  color: #a6c7cc;
  background-color: transparent;
  border: none;
}

.subscribe-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 23px;
  background: url(../img/icons/subscription-icon.svg) no-repeat center;
  background-size: contain;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .subscribe-btn {
    display: block;
    margin-bottom: 20px;
  }
}

.infocard {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (max-width: 767.98px) {
  .infocard {
    margin: 10px 0;
  }
}

@media (min-width: 525px) {
  .infocard {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .infocard {
    width: 33.33333%;
  }
}

.infocard:hover .infocard__wrapper {
  border-color: #8cdee2;
}

.infocard__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}

.infocard__wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 25px 30px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 5px 0px #a6c7cc;
}

@media (max-width: 1199.98px) {
  .infocard__wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.infocard__icon {
  width: 90px;
  height: 90px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1199.98px) {
  .infocard__icon {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1199.98px) {
  .infocard__text {
    text-align: center;
  }
}

.infocard--home:nth-child(1) .infocard__icon {
  background-image: url(../img/icons/mission-1.svg);
}

.infocard--home:nth-child(1):hover .infocard__icon {
  background-image: url(../img/icons/mission-hover-1.svg);
}

.infocard--home:nth-child(2) .infocard__icon {
  background-image: url(../img/icons/mission-2.svg);
}

.infocard--home:nth-child(2):hover .infocard__icon {
  background-image: url(../img/icons/mission-hover-2.svg);
}

.infocard--home:nth-child(3) .infocard__icon {
  background-image: url(../img/icons/mission-3.svg);
}

.infocard--home:nth-child(3):hover .infocard__icon {
  background-image: url(../img/icons/mission-hover-3.svg);
}

.infocard--volunteers:nth-child(1) .infocard__icon {
  background-image: url(../img/icons/advantages-1.svg);
}

.infocard--volunteers:nth-child(1):hover .infocard__icon {
  background-image: url(../img/icons/advantages-hover-1.svg);
}

.infocard--volunteers:nth-child(2) .infocard__icon {
  background-image: url(../img/icons/advantages-2.svg);
}

.infocard--volunteers:nth-child(2):hover .infocard__icon {
  background-image: url(../img/icons/advantages-hover-2.svg);
}

.infocard--volunteers:nth-child(3) .infocard__icon {
  background-image: url(../img/icons/advantages-3.svg);
}

.infocard--volunteers:nth-child(3):hover .infocard__icon {
  background-image: url(../img/icons/advantages-hover-3.svg);
}

.infocard--citizens:nth-child(1) .infocard__icon {
  background-image: url(../img/icons/citizens-1.svg);
}

.infocard--citizens:nth-child(1):hover .infocard__icon {
  background-image: url(../img/icons/citizens-hover-1.svg);
}

.infocard--citizens:nth-child(2) .infocard__icon {
  background-image: url(../img/icons/citizens-2.svg);
}

.infocard--citizens:nth-child(2):hover .infocard__icon {
  background-image: url(../img/icons/citizens-hover-2.svg);
}

.infocard--citizens:nth-child(3) .infocard__icon {
  background-image: url(../img/icons/citizens-3.svg);
}

.infocard--citizens:nth-child(3):hover .infocard__icon {
  background-image: url(../img/icons/citizens-hover-3.svg);
}

.infocard--nco:nth-child(1) .infocard__icon {
  background-image: url(../img/icons/nco-1.svg);
}

.infocard--nco:nth-child(1):hover .infocard__icon {
  background-image: url(../img/icons/nco-hover-1.svg);
}

.infocard--nco:nth-child(2) .infocard__icon {
  background-image: url(../img/icons/nco-2.svg);
}

.infocard--nco:nth-child(2):hover .infocard__icon {
  background-image: url(../img/icons/nco-hover-2.svg);
}

.infocard--nco:nth-child(3) .infocard__icon {
  background-image: url(../img/icons/nco-3.svg);
}

.infocard--nco:nth-child(3):hover .infocard__icon {
  background-image: url(../img/icons/nco-hover-3.svg);
}

.infocard__title {
  margin-bottom: 15px;
  font-size: 14px;
}

.infocard__description {
  width: 200px;
  font-size: 14px;
}

@media (max-width: 1199.98px) {
  .infocard__description {
    width: 100%;
  }
}

.slider-controls__pagination {
  position: static;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 5px;
  font-size: 14px;
  color: #a6c7cc;
}

.slider-controls__pagination .swiper-pagination-current {
  align-self: flex-start;
  width: 21px;
  margin: 0 5px 5px 0;
  font-size: 18px;
  color: #000;
}

.slider-controls__pagination .swiper-pagination-total {
  align-self: flex-end;
  margin: 0 0 0 3px;
}

.slider-controls__pagination-wrapper {
  position: relative;
  width: 80px;
}

.slider-controls__next, .slider-controls__prev {
  top: 0;
  bottom: 0;
  margin: 0;
  background-size: contain;
}

.slider-controls__next:focus, .slider-controls__prev:focus {
  outline: none;
}

.slider-controls__next {
  right: 0;
  width: 8px;
  height: 15px;
  background-image: url(../img/icons/main-slider-chevron-right.svg);
}

.slider-controls__prev {
  left: 0;
  width: 8px;
  height: 15px;
  background-image: url(../img/icons/main-slider-chevron-left.svg);
}

.slider-controls__scrollbar {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 1px;
  margin: 2px 0;
  background-color: #a6c7cc;
}

.slider-controls__scrollbar.info__scrollbar {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 1200px) {
  .slider-controls__scrollbar.info__scrollbar {
    width: 83.33333%;
  }
}

.slider-controls__scrollbar .swiper-scrollbar-drag {
  position: absolute;
  height: 3px;
  margin-top: -1px;
  background-color: inherit;
  cursor: pointer;
}

.articles-nav {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  position: relative;
}

.articles-nav .articles-nav__link:hover, .articles-nav .articles-nav__link:active, .articles-nav .articles-nav__link.active, .articles-nav .articles-nav__mobile {
  color: #ffad00;
  border-color: #ffad00;
}

@media (min-width: 768px) {
  .articles-nav {
    width: 16.66667%;
  }
}

.articles-nav__list {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 767.98px) {
  .articles-nav__list {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 10px 15px;
    background-color: #fff;
    opacity: 0;
    z-index: 0;
    transition: opacity .2s ease;
  }
  .articles-nav__list.active {
    opacity: 1;
    z-index: 9;
  }
}

.articles-nav__link, .articles-nav__mobile {
  display: inline-block;
  padding: 15px 0 2px;
  padding-top: 15px;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px dotted #000;
  cursor: pointer;
}

.articles-nav__mobile {
  position: relative;
  display: none;
}

@media (max-width: 767.98px) {
  .articles-nav__mobile {
    display: inline-block;
  }
}

.articles-nav__mobile::after {
  content: '';
  position: absolute;
  right: -20px;
  width: 15px;
  height: 15px;
  background: url(../img/icons/chevron-bottom.svg) center no-repeat;
  background-size: contain;
}

.article-preview {
  display: block;
  color: #000;
  text-decoration: none;
  border-radius: 2px;
  box-shadow: 0px 0px 9px 3px rgba(166, 199, 204, 0.15);
}

.article-preview__heading {
  width: 100%;
  height: 175px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

@media (max-width: 1199.98px) {
  .article-preview__heading {
    height: 140px;
  }
}

@media (max-width: 991.98px) {
  .article-preview__heading {
    height: 160px;
  }
}

@media (max-width: 767.98px) {
  .article-preview__heading {
    max-height: 250px;
    height: 100%;
  }
}

.article-preview__body {
  padding: 20px 25px;
}

.article-preview__title {
  margin-bottom: 15px;
  font-size: 14px;
}

.article-preview__text {
  min-height: 128px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 300;
}

.article-preview__info {
  font-size: 12px;
  font-weight: 300;
  color: #a6c7cc;
}

.article-preview__wrapper {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .article-preview__wrapper {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .article-preview__wrapper {
    width: 33.33333%;
  }
}

.article-preview__wrapper--first {
  width: 100%;
  margin-right: 15px;
  margin-left: 15px;
}

.article-preview__wrapper--first .article-preview {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  height: 370px;
}

@media (max-width: 767.98px) {
  .article-preview__wrapper--first .article-preview {
    height: auto;
  }
}

.article-preview__wrapper--first .article-preview__heading {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: auto;
  padding-left: 0;
}

@media (min-width: 768px) {
  .article-preview__wrapper--first .article-preview__heading {
    width: 58.33333%;
  }
}

@media (min-width: 992px) {
  .article-preview__wrapper--first .article-preview__heading {
    width: 66.66667%;
  }
}

@media (max-width: 767.98px) {
  .article-preview__wrapper--first .article-preview__heading {
    padding-right: 0;
  }
}

.article-preview__wrapper--first .article-preview__body {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
}

@media (min-width: 768px) {
  .article-preview__wrapper--first .article-preview__body {
    width: 41.66667%;
  }
}

@media (min-width: 992px) {
  .article-preview__wrapper--first .article-preview__body {
    width: 33.33333%;
  }
}

.article-preview__wrapper--first .article-preview__title {
  font-size: 18px;
}

.article-preview__wrapper--first .article-preview__text {
  margin-bottom: 40px;
  font-size: 16px;
}

.map {
  position: relative;
  height: 770px;
}

.map--calendar {
  height: 570px;
}

.map--calendar .map-form__container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575.98px) {
  .map {
    height: auto;
  }
}

.map__cloud {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../img/maps-cloud.png) no-repeat center;
  background-size: cover;
  pointer-events: none;
}

.map-screen {
  height: 100%;
}

@media (max-width: 575.98px) {
  .map-screen {
    height: 750px;
  }
}

.map--event {
  height: 500px;
  margin: 0 auto 40px !important;
}

.map .gm-style .gm-style-iw {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  overflow: visible !important;
}

@media (max-width: 767.98px) {
  .map .gm-style .gm-style-iw {
    min-width: 250px !important;
    min-height: 207px;
  }
}

.map .gm-style .gm-style-iw div {
  overflow: visible !important;
}

.map .gm-style .gm-style-iw button {
  top: 12px !important;
  right: 16px !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0 !important;
  font-size: 30px !important;
  line-height: 0.4;
  font-weight: 100;
  background: url(../img/icons/map/close.svg) no-repeat center !important;
  background-size: contain !important;
}

.map .gm-style .gm-style-iw button img {
  display: none !important;
}

.map .gm-style .gm-style-iw-t::after {
  content: none;
}

@media (max-width: 767.98px) {
  .map .gm-style-iw-d {
    min-width: 250px !important;
    min-height: 207px;
  }
}

.map-event {
  position: relative;
  max-width: 292px;
  padding: 25px 20px;
  font-size: 14px;
  border: 1px solid #bfd4d7;
}

.map-event::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: calc(50% - 7.5px);
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-bottom: 1px solid #bfd4d7;
  border-right: 1px solid #bfd4d7;
  transform: rotate(45deg);
}

.map-event__close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 0;
  font-size: 30px;
  line-height: 0.4;
  font-weight: 100;
  color: #bfd4d7;
  background-color: transparent;
  border: none;
}

.map-event__heading {
  font-size: 15px;
  font-weight: 500;
  color: #ffad02;
}

.map-event__heading span {
  display: inline-block;
}

.map-event__organizer {
  margin-top: 15px;
  font-size: 12px;
}

.map-event__organizer-name {
  margin-top: 5px;
  color: #73bfc8;
  text-transform: uppercase;
}

.map-event .section-contacts {
  flex-direction: column;
  padding: 0;
}

.map-event .section-contacts p {
  margin: 5px 0;
}

.map-event__link {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-right: 25px;
  text-align: right;
  color: #ffad02;
  text-decoration: none;
}

.map-event__link:hover {
  color: #ffad02;
  text-decoration: none;
}

.map-event__link::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 10px;
  margin-top: -5px;
  background: url(../img/icons/map/arrow-gradient-right.svg) no-repeat center;
  background-size: contain;
}

.mmenu {
  position: fixed;
  display: none;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  margin: 0;
  padding: 0 0 50px 20px;
  font-size: 18px;
  background-color: #fff;
  z-index: 99999;
  transform: translateX(400px);
  transition: all .2s ease;
  overflow: auto;
}

@media (max-width: 575.98px) {
  .mmenu {
    width: 100%;
    padding: 0 20px 50px;
  }
}

.mmenu-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  cursor: pointer;
}

.mmenu-title {
  margin-bottom: 30px;
  font-size: 80px;
  line-height: 0.8;
  font-weight: 100;
  color: #d7d7d7;
  text-transform: uppercase;
}

@media (max-width: 575.98px) {
  .mmenu-title {
    font-size: 70px;
  }
}

.mmenu-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
}

.mmenu-button {
  width: 100%;
  margin: 5px 0 5px -20px;
  padding: 15px 20px 15px 40px;
  color: #000;
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  border: none;
  transition: all .2s ease;
}

.mmenu-button:hover {
  color: #fff;
  background-color: #73bfc8;
}

@media (max-width: 575.98px) {
  .mmenu-button {
    margin: 5px 0;
    border: 1px solid #73bfc8;
  }
}

.mmenu-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 50px;
  line-height: 0.8;
  color: #d7d7d7;
  background-color: transparent;
  border: none;
}

.mmenu-burger {
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  font-size: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .mmenu-burger {
    display: flex;
  }
}

.mmenu-burger img {
  max-width: 100%;
}

.mmenu-translate {
  transition: all .2s ease;
}

.mmenu .site-search {
  display: none;
}

@media (max-width: 575.98px) {
  .mmenu .site-search {
    display: flex;
    margin-bottom: 20px;
  }
}

body.blocked {
  overflow: hidden;
}

.form__title {
  margin-bottom: 25px;
  font-size: 20px;
}

.form__label {
  display: block;
  margin-bottom: 5px;
}

.form__input-wrapper--search {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.form__input {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 20px;
  border: 1px solid #b7ebef;
}

.form__input--search {
  margin: 0;
  padding: 12px 20px;
}

.form__input-group {
  display: flex;
  justify-content: space-between;
}

.form__input-group .form__input {
  width: 47%;
}

.form__input-group input:first-child {
  margin-right: 15px;
}

.map-form__container {
  z-index: 1;
}

.map-form__wrapper {
  position: absolute;
  top: 50%;
  width: 360px;
  padding: 40px 35px;
  background-color: #fff;
  border: 1px solid #ceeef0;
  transform: translateY(-50%);
  z-index: 1;
}

@media (max-width: 575.98px) {
  .map-form__wrapper {
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 20px;
    transform: none;
  }
}

.map-form__more {
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 3px;
  font-size: 14px;
  font-weight: 300;
  color: #ffab00;
  border-bottom: 1px dotted #ffab00;
}

.map-form__buttons {
  display: flex;
  justify-content: space-between;
}

.map-form__buttons .btn:first-child {
  margin-right: 15px;
}

.about {
  padding: 45px 0;
  background-color: #f9fbfd;
}

.about .section-title__wrapper {
  margin: 0 -15px 20px;
}

.about__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.about__main {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 992px) {
  .about__main {
    width: 66.66667%;
  }
}

.about__statistics {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .about__statistics {
    margin-bottom: 10px;
  }
}

.about__numbers {
  width: 20%;
}

@media (max-width: 767.98px) {
  .about__numbers {
    width: 40%;
    margin-bottom: 20px;
  }
}

.about__numbers .title {
  font-size: 45px;
  font-weight: 900;
  color: #b1cdd1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSItNi43JSIgeTE9IjEyLjMlIiB4Mj0iMTA2LjclIiB5Mj0iODcuNyUiPjxzdG9wIHN0b3AtY29sb3I9IiNjMWQ1ZDgiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iI2ExYzVjYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image: -webkit-gradient(linear, -6.7% 12.3%, 106.7% 87.7%, color-stop(0, #c1d5d8), color-stop(1, #a1c5ca));
  background-image: -webkit-repeating-linear-gradient(328deg, #c1d5d8 0%, #a1c5ca 100%);
  background-image: repeating-linear-gradient(122deg, #c1d5d8 0%, #a1c5ca 100%);
  background-image: -ms-repeating-linear-gradient(328deg, #c1d5d8 0%, #a1c5ca 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media \0screen\,screen\9 {
  .about__numbers .title .gradient {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc1d5d8",endColorstr="#ffa1c5ca",GradientType=1);
  }
}

@media (max-width: 1199.98px) {
  .about__numbers .title {
    font-size: 40px;
  }
}

.about__numbers .description {
  font-size: 16px;
}

.about__text {
  margin-bottom: 30px;
}

.about__img {
  padding-left: 15px;
  padding-right: 15px;
  width: 33.33333%;
  background: url(../img/main-about-bg.png) no-repeat;
  background-position: center top;
  background-size: contain;
}

.socials-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.socials-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
}

.socials-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: linear-gradient(122deg, #c1d5d8 0%, #a1c5ca 100%);
  border-radius: 50%;
  transition: background .2s ease;
}

.socials-link:hover {
  background: linear-gradient(122deg, #ffce2b 0%, #ffab00 100%);
}

.socials-link img {
  max-width: 65%;
}

.img-screen__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 100px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767.98px) {
  .img-screen__row {
    padding: 50px 0;
    background-position: left;
  }
}

.img-screen__content {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  position: relative;
}

@media (min-width: 768px) {
  .img-screen__content {
    width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  .img-screen__content {
    width: 41.66667%;
  }
}

@media (min-width: 1200px) {
  .img-screen__content {
    left: 8.33333%;
  }
}

.volunteers .img-screen__row {
  background-image: url(../img/volunteers-bg.jpg);
}

.citizens .img-screen__row {
  background-image: url(../img/citizens-bg.jpg);
}

.nco .img-screen__row {
  background-image: url(../img/nco-bg.jpg);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  background-color: rgba(174, 194, 198, 0.6);
  z-index: 999;
  transition: all .3s ease;
  overflow: scroll;
}

.modal-wrapper {
  padding-top: 160px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  transition: all .3s ease;
}

.modal-close {
  position: absolute;
  top: 50%;
  right: 30px;
  height: 100%;
  padding: 0;
  font-size: 40px;
  line-height: 1;
  color: #c6d5d8;
  background: transparent;
  border: none;
  transform: translateY(-50%);
}

.modal-head {
  position: relative;
  margin: 20px 0;
  padding: 0 30px;
  font-size: 30px;
}

.modal-body {
  padding: 0 30px;
}

.modal-title {
  padding: 10px 0;
  border-bottom: 2px solid #c6d5d8;
}

.modal-radios-wrapper {
  margin: 20px 0 30px;
}

.modal-radios-wrapper.account-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-confirmation {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
}

.modal-confirmation span {
  color: #ffab00;
}

.modal-custom-radio {
  cursor: pointer;
}

.modal-custom-radio input[type=radio] {
  display: none;
}

.modal-custom-radio input[type=radio]:checked + span::before {
  background-color: #f8aa10;
}

.modal-custom-radio span {
  position: relative;
  padding-left: 20px;
  font-size: 20px;
  line-height: 1;
}

.modal-custom-radio span::before {
  content: '';
  position: absolute;
  top: 45%;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #b2c2c6;
  border: 1px solid #d3edf1;
  border-radius: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.modal-custom-radio:hover span::before {
  background-color: #f8aa10;
}

.modal .recover-pwd {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #73bfc8;
  text-decoration: underline;
  cursor: pointer;
}

.modal .recover-pwd:hover {
  color: #ffab00;
}

.modal .btn {
  font-size: 20px;
  margin-bottom: 25px;
  padding: 20px;
}

.modal-footer {
  padding: 20px 30px;
  color: #9a9a9a;
  text-align: center;
  background-color: #daf4f8;
}

.modal-footer span {
  display: inline-block;
  color: #000;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.vue-modal-enter {
  opacity: 0;
}

.vue-modal-leave-active {
  opacity: 0;
}

.vue-modal-enter .modal-container,
.vue-modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.pagination {
  width: 100%;
}

.pagination__wrapper {
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination__button {
  width: 30px;
  height: 30px;
  margin: 0 -2px;
  list-style-type: none;
  font-size: 24px;
  line-height: 1.2;
  border-radius: 50%;
}

.pagination__button a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 2px 0 0;
  color: #000;
  text-decoration: none !important;
}

.pagination__button--active, .pagination__button:hover {
  background-color: #73bfc8;
}

.pagination__button--active a, .pagination__button:hover a {
  color: #fff;
}

.pagination__button--gap {
  padding: 1px 6px;
  cursor: default;
}

.pagination__button--gap:hover {
  background-color: transparent;
}

.sort-type {
  font-size: 15px;
}

@media (max-width: 575.98px) {
  .sort-type {
    display: block;
    margin-bottom: 10px;
  }
}

.sort-type input[type=radio]:checked + span, .sort-type span:hover {
  color: #ffad00;
  text-decoration: underline;
  cursor: pointer;
}

.sort-type input[type=radio] {
  display: none;
}

* {
  box-sizing: border-box;
}

input {
  border: none;
}

input:active, input:focus {
  outline: none;
}

.menu__nav, .topline__container, .infocard__container, .map--event, .map-form__container, .about__container, .img-screen, .map-heading, .footer, .header_underline, .header__container, .mission, .home__info, .joint-action, .useful-info, .news__container, .news-article__container, .news-article .previews-line, .search-line, .search-results {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 1170px;
  margin: auto;
}

@media (max-width: 1199.98px) {
  .menu__nav, .topline__container, .infocard__container, .map--event, .map-form__container, .about__container, .img-screen, .map-heading, .footer, .header_underline, .header__container, .mission, .home__info, .joint-action, .useful-info, .news__container, .news-article__container, .news-article .previews-line, .search-line, .search-results {
    width: 960px;
  }
}

@media (max-width: 991.98px) {
  .menu__nav, .topline__container, .infocard__container, .map--event, .map-form__container, .about__container, .img-screen, .map-heading, .footer, .header_underline, .header__container, .mission, .home__info, .joint-action, .useful-info, .news__container, .news-article__container, .news-article .previews-line, .search-line, .search-results {
    width: 720px;
  }
}

@media (max-width: 767.98px) {
  .menu__nav, .topline__container, .infocard__container, .map--event, .map-form__container, .about__container, .img-screen, .map-heading, .footer, .header_underline, .header__container, .mission, .home__info, .joint-action, .useful-info, .news__container, .news-article__container, .news-article .previews-line, .search-line, .search-results {
    width: 540px;
  }
}

@media (max-width: 575.98px) {
  .menu__nav, .topline__container, .infocard__container, .map--event, .map-form__container, .about__container, .img-screen, .map-heading, .footer, .header_underline, .header__container, .mission, .home__info, .joint-action, .useful-info, .news__container, .news-article__container, .news-article .previews-line, .search-line, .search-results {
    width: 100%;
  }
}

.menu__nav-row::before, .site-search__wrapper::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #a1c5ca;
  background: linear-gradient(135deg, #c1d5d8 0%, #a1c5ca 100%);
}

.two-xl-col-offset {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .two-xl-col-offset {
    width: 16.66667%;
  }
}

body {
  font-family: Circe, sans-serif;
  background-color: #fff;
}

p, h1, h2, h3, h4, h5 {
  margin: 0;
}

header {
  margin-bottom: 20px;
}

.site-heading {
  margin-bottom: 35px;
  padding-right: 40px;
  font-size: 36px;
  font-weight: 400;
}

@media (max-width: 575.98px) {
  .site-heading {
    font-size: 26px;
  }
}

.site-description {
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: 300;
}

.section-title {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 5px;
  font-size: 34px;
  font-weight: 300;
}

@media (max-width: 575.98px) {
  .section-title {
    font-size: 25px;
  }
}

.section-title__wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 575.98px) {
  .section-title__wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.section-subtitle {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  font-size: 16px;
}

.section-contacts {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.section-contacts p {
  position: relative;
  min-width: 30%;
  padding-left: 20px;
  line-height: 0.9;
}

@media (max-width: 991.98px) {
  .section-contacts p {
    min-width: 100%;
    margin-bottom: 15px;
  }
}

.section-contacts p::before {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.section-contacts .address::before {
  background-image: url(../img/icons/event-geo.svg);
}

.section-contacts .time::before {
  background-image: url(../img/icons/event-time.svg);
}

.section-contacts .nco-name {
  text-transform: uppercase;
}

.section-contacts .nco-name::before {
  background-image: url(../img/icons/event-org.svg);
}

.footer__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.footer__copyright {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .footer__copyright {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .footer__copyright {
    width: 16.66667%;
  }
}

@media (max-width: 767.98px) {
  .footer__copyright {
    padding: 30px 15px;
  }
}

.footer__logo {
  max-width: 105px;
  margin-bottom: 10px;
}

.footer__name {
  font-size: 22px;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .footer__name {
    font-size: 20px;
  }
}

.footer__desc {
  font-size: 12px;
  text-align: center;
}

.footer__menu {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0 40px 0 50px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .footer__menu {
    width: 41.66667%;
  }
}

@media (min-width: 1200px) {
  .footer__menu {
    width: 50%;
  }
}

@media (max-width: 1199.98px) {
  .footer__menu {
    padding: 0;
  }
}

.footer__menu-wrapper {
  width: 100%;
  padding: 60px 55px;
  border-left: 2px solid #edf4f5;
  border-right: 2px solid #edf4f5;
}

@media (max-width: 1199.98px) {
  .footer__menu-wrapper {
    padding: 60px 20px;
  }
}

@media (max-width: 767.98px) {
  .footer__menu-wrapper {
    padding: 0 15px;
    border: none;
  }
}

.footer__menu-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__link {
  width: 30%;
  margin-bottom: 20px;
  color: #000;
  font-weight: 400;
  text-decoration: none;
  transition: color .2s ease;
}

@media (max-width: 1199.98px) {
  .footer__link {
    width: 31%;
  }
}

@media (max-width: 575.98px) {
  .footer__link {
    width: 40%;
  }
}

.footer__link:hover {
  color: #ffad00;
}

.footer__corp-data {
  font-weight: 300;
}

.footer__contacts {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding: 60px 50px;
  font-weight: 300;
}

@media (min-width: 768px) {
  .footer__contacts {
    width: 33.33333%;
  }
}

@media (max-width: 1199.98px) {
  .footer__contacts {
    padding: 60px 22px;
  }
}

@media (max-width: 991.98px) {
  .footer__contacts {
    padding: 60px 15px;
  }
}

@media (max-width: 767.98px) {
  .footer__contacts {
    padding: 20px 15px;
  }
}

.footer__socials {
  margin-bottom: 20px;
}

.footer__address {
  font-size: 13px;
}

.privacy-policy {
  display: inline-block;
  margin-bottom: 15px;
  padding-bottom: 2px;
  font-weight: 300;
  color: #ffab00;
  text-decoration: none;
  border-bottom: 1px dotted #ffab00;
}

.header_underline {
  height: 1px;
  background-color: #c1d5d8;
}

.header__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 100px 0 160px;
}

@media (max-width: 991.98px) {
  .header__row {
    padding: 50px 0 80px;
  }
}

.header__info {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 768px) {
  .header__info {
    width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  .header__info {
    width: 41.66667%;
  }
}

.header__buttons-wrapper {
  margin: 15px 0;
}

.header__buttons-wrapper .btn {
  width: 145px;
}

@media (max-width: 767.98px) {
  .header__buttons-wrapper .btn {
    width: 100%;
  }
}

.header--main {
  background-image: url(../img/main-page-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1199.98px) {
  .header--main {
    background-position: 10%;
  }
}

@media (max-width: 767.98px) {
  .header--main {
    background-position: 10%;
  }
}

.info {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 45px;
}

.info__nav {
  margin-bottom: 20px;
}

.info__slider {
  width: 1170px;
  padding: 0;
}

@media (max-width: 1599.98px) {
  .info__slider {
    width: 100%;
  }
}

.info__slider-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  position: relative;
  overflow: visible;
}

@media (min-width: 768px) {
  .info__slider-wrapper {
    width: 83.33333%;
  }
}

.info__slider .swiper-wrapper {
  margin-bottom: 40px;
  padding: 5px;
}

@media (max-width: 767.98px) {
  .info__slider .swiper-wrapper {
    padding: 0;
  }
}

.info__footer-buttons {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info__buttons-col {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 768px) {
  .info__buttons-col {
    width: 83.33333%;
  }
}

@media (max-width: 767.98px) {
  .info__buttons-col {
    display: flex;
    flex-wrap: wrap;
  }
}

.joint-action__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.joint-action__col {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 525px) {
  .joint-action__col {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .joint-action__col {
    width: 25%;
  }
}

@media (max-width: 991.98px) {
  .joint-action__col {
    margin-bottom: 35px;
  }
}

@media (max-width: 575.98px) {
  .joint-action__col.card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.joint-action__col .card__head {
  display: flex;
  align-items: center;
  min-height: 120px;
  margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .joint-action__col .card__head {
    min-height: 0;
  }
}

.joint-action__col .card__title {
  margin-bottom: 20px;
}

.joint-action__col .card__description {
  padding-right: 50px;
  font-size: 14px;
}

@media (max-width: 575.98px) {
  .joint-action__col .card__description {
    padding: 0 10vw;
    text-align: center;
  }
}

.useful-info__slider {
  margin: 0 -15px 30px;
}

@media (max-width: 575.98px) {
  .useful-info__slider {
    padding: 0 15px;
  }
}

.useful-info__slider .swiper-wrapper {
  margin-bottom: 30px;
  padding: 15px;
}

@media (max-width: 575.98px) {
  .useful-info__slider .swiper-wrapper {
    padding: 15px 0;
  }
}

.useful-info__slider .slide {
  padding: 30px 30px 25px;
  color: #000;
  text-decoration: none;
  border: 1px solid transparent;
  box-shadow: 0 0 9px 3px rgba(166, 199, 204, 0.15);
  transition: border-color .2s ease;
}

.useful-info__slider .slide:hover {
  border-color: #ceeef0;
}

.useful-info__slider .slide__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.useful-info__slider .slide__arrow {
  max-width: 19px;
}

.useful-info__slider .slide__description {
  font-size: 14px;
}

.useful-info__controls {
  padding: 0 15px;
}

.news__row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.news__articles-list {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .news__articles-list {
    width: 75%;
  }
}

@media (max-width: 767.98px) {
  .news__articles-list {
    order: 1;
  }
}

.news .common-articles {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.news__nav {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding-left: 45px;
}

@media (min-width: 768px) {
  .news__nav {
    width: 25%;
  }
}

@media (max-width: 1199.98px) {
  .news__nav {
    padding-left: 15px;
  }
}

@media (max-width: 767.98px) {
  .news__nav {
    order: 0;
  }
}

@media (max-width: 767.98px) {
  .news__nav .articles-nav__mobile {
    margin-bottom: 30px;
  }
}

.news__nav .articles-nav__list {
  top: 50%;
}

.news-article__wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.news-article .article-preview__wrapper {
  padding: 0;
}

.news-article .section-title {
  font-size: 24px;
  font-weight: 500;
}

.news-article .section-title__wrapper {
  margin: 30px -15px;
}

.common-article {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (max-width: 991.98px) {
  .common-article {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.common-article img {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  padding: 0 30px 0 0;
  float: left;
}

@media (min-width: 992px) {
  .common-article img {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .common-article img {
    order: 0;
    width: 100%;
    padding: 0;
  }
}

.common-article p {
  margin-top: 20px;
}

.common-article ul, .common-article ol {
  padding-left: 20px;
}

@media (max-width: 767.98px) {
  .common-article ul, .common-article ol {
    padding-left: 0;
  }
}

.common-article li {
  margin-top: 10px;
  padding-right: 20px;
  transform: translateX(20px);
  animation: fixlists 1s;
}

.common-article a {
  color: #ffad00;
  text-decoration: none;
}

.common-article .main-info {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: inline-block;
  padding: 0;
}

@media (min-width: 992px) {
  .common-article .main-info {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .common-article .main-info {
    margin-bottom: 20px;
  }
}

.common-article .main-info .title {
  margin: 20px 0 0;
  font-size: 22px;
  line-height: 0.9;
}

.category {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  color: #a6c7cc;
}

@keyframes fixlists {
  0% {
    text-indent: -0.001em;
  }
  100% {
    text-indent: 0;
  }
}

.search-line__form {
  display: flex;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.search-line__btn {
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  transform: translateY(-50%);
}

.search-line__btn img {
  width: 17px;
}

.search-line__sort {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 40px;
  text-align: right;
}

@media (max-width: 575.98px) {
  .search-line__sort .vertical-line {
    display: none;
  }
}

.search-results {
  margin-bottom: 20px;
}

.search-results .result {
  display: block;
  padding: 20px 0;
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #c1d5d8;
}

.search-results .result:first-child {
  border-top: 1px solid #c1d5d8;
}

.search-results .result__title {
  margin-bottom: 10px;
  font-size: 14px;
}

.search-results .result__text {
  margin-bottom: 10px;
  font-size: 14px;
}

.search-results .result .category {
  font-size: 12px;
}
