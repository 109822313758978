.article-preview {
    display: block;
    color: #000;
    text-decoration: none;
    border-radius: 2px;
    box-shadow: 0px 0px 9px 3px rgba(166, 199, 204, 0.15);

    &__heading {
        width: 100%;
        height: 175px;
        object-fit: cover;
        font-family: "object-fit: cover;";

        @include respond-to('xl') {
            height: 140px;
        }

        @include respond-to('lg') {
            height: 160px;
        }

        @include respond-to('md') {
            max-height: 250px;
            height: 100%;
        }
    }

    &__body {
        padding: 20px 25px;
    }

    &__title {
        margin-bottom: 15px;
        font-size: 14px;
    }

    &__text {
        min-height: 128px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 300;
    }

    &__info {
        font-size: 12px;
        font-weight: 300;
        color: $mainBlueColor;
    }

    &__wrapper {
        @include grid-col(12 null 6 4);
        margin-bottom: 40px;

        &--first {
            width: 100%;
            margin-right: 15px;
            margin-left: 15px;

            .article-preview {
                @include grid-row();
                height: 370px;

                @include respond-to('md') {
                    height: auto;
                }
            }
    
            .article-preview__heading {
                @include grid-col(12 null 7 8);
                height: auto;
                padding-left: 0;

                @include respond-to('md') {
                    padding-right: 0;
                }
            }
    
            .article-preview__body {
                @include grid-col(12 null 5 4);
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 30px;
            }

            .article-preview__title {
                font-size: 18px;
            }

            .article-preview__text {
                margin-bottom: 40px;
                font-size: 16px;
            }
        }
    }
}