.search-line {
    @extend %container;

    &__form {
        @include grid-row();
    }

    &__btn {
        position: absolute;
        top: 50%;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 100%;
        padding: 0;
        background-color: transparent;
        border: none;
        transform: translateY(-50%);

        img {
            width: 17px;
        }
    }

    &__sort {
        @include grid-col(12);
        margin-bottom: 40px;
        text-align: right;

        .vertical-line {
            @include respond-to('sm') {
                display: none;
            }
        }
    }
}

.search-results {
    @extend %container;
    margin-bottom: 20px;

    .result {
        display: block;
        padding: 20px 0;
        text-decoration: none;
        color: #000;
        border-bottom: 1px solid #c1d5d8;

        &:first-child {
            border-top: 1px solid #c1d5d8;
        }

        &__title {
            margin-bottom: 10px;
            font-size: 14px;
        }

        &__text {
            margin-bottom: 10px;
            font-size: 14px;
        }

        .category {
            font-size: 12px;
        }
    }
}