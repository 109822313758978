button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.btn {
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    background-color: #73bfc8;
    background: linear-gradient(135deg, #8cdee2 0%,#73bfc8 100%);
    border: none;

    &:hover {
        text-decoration: none;
    }

    &--yellow {
        background-color: #ffab00;
        background: linear-gradient(135deg, #ffce2b 0%,#ffab00 100%);;
    }

    &--header-register {
        margin-right: 25px;

        @include respond-to('md') {
            margin: 0 0 20px;
        }
    }

    &--news {
        width: 144px;
        margin-right: 30px;

        @include respond-to('md') {
            width: 100%;
            margin: 0 0 20px;
        }
    }

    &--joint-action {
        padding: 15px;
    }
}

.subscribe-btn {
    position: relative;
    padding-left: 42px;
    font-size: 14px;
    color: $mainBlueColor;
    background-color: transparent;
    border: none;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 30px;
        height: 23px;
        background: url(../img/icons/subscription-icon.svg) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
    }

    @include respond-to('md') {
        display: block;
        margin-bottom: 20px;
    }
}