.header {
    &__container {
        @extend %container;
    }

    &__row {
        @include grid-row();
        padding: 100px 0 160px;

        @include respond-to('lg') {
            padding: 50px 0 80px;
        }
    }

    &__info {
        @include grid-col(12 null 8 null 5);
    }

    &__buttons-wrapper {
        margin: 15px 0;

        .btn {
            width: 145px;

            @include respond-to('md') {
                width: 100%;
            }
        }
    }

    &--main {
        background-image: url(../img/main-page-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include respond-to('xl') {
            background-position: 10%;
        }

        @include respond-to('md') {
            background-position: 10%;
        }
    }
}

.mission {
    @extend %container;
}

.home__info {
    @extend %container;
}

.info {
    @include grid-row();
    margin-bottom: 45px;

    &__nav {
        margin-bottom: 20px;
    }

    &__slider {
        width: 1170px;
        padding: 0;

        @include respond-to('xxl') {
            width: 100%;
        }

        &-wrapper {
            @include grid-col(12 null 10);
            position: relative;
            overflow: visible;
        }

        .swiper-wrapper {
            margin-bottom: 40px;
            padding: 5px;

            @include respond-to('md') {
                padding: 0;
            }
        }
    }

    &__footer-buttons {
        @include grid-row();
    }

    &__buttons-col {
        @include grid-col(12  null 10);

        @include respond-to('md') {
            display: flex; 
            flex-wrap: wrap;
        }
    }
}

.joint-action {
    @extend %container;

    &__row {
        @include grid-row();
    }

    &__col {
        @include grid-col(12 6 null 3); 

        @include respond-to('lg') {
            margin-bottom: 35px;
        }

        &.card {

            @include respond-to('sm') {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .card {
            &__head {
                display: flex;
                align-items: center;
                min-height: 120px;
                margin-bottom: 25px;

                @include respond-to('sm') {
                    min-height: 0;
                }
            }

            &__title {
                margin-bottom: 20px;
            }

            &__description {
                padding-right: 50px;
                font-size: 14px;

                @include respond-to('sm') {
                    padding: 0 10vw;
                    text-align: center;
                }
            }
        }
    }
}

.useful-info {
    @extend %container;

    &__slider {
        margin: 0 -15px 30px;

        @include respond-to('sm') {
            // margin: 0;
            padding: 0 15px;
        }

        .swiper-wrapper {
            margin-bottom: 30px;
            padding: 15px;

            @include respond-to('sm') {
                padding: 15px 0;
            }
        }

        .slide {
            padding: 30px 30px 25px;
            color: #000;
            text-decoration: none;
            border: 1px solid transparent;
            box-shadow: 0 0 9px 3px rgba(166,199,204,.15);
            transition: border-color .2s ease;

            &:hover {
                border-color: #ceeef0;
            }

            &__head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
            }

            &__arrow {
                max-width: 19px;
            }

            &__description {
                font-size: 14px;
            }
        }
    }

    &__controls {
        padding: 0 15px;
    }
}