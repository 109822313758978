@import "libs";
@import "vars";
@import "mixins/base";
@import "mixins/breakpoints-manager";
@import "fonts";
@import "components";

* {
    box-sizing: border-box;
}

input {
    border: none;

    &:active, &:focus {
        outline: none;
    }
}

%container {
    @include grid-container();
    width: 1170px;
    margin: auto;

    @include respond-to('xl') {
        width: 960px;
    }

    @include respond-to('lg') {
        width: 720px;
    }

    @include respond-to('md') {
        width: 540px;
    }

    @include respond-to('sm') {
        width: 100%;
    }
}

%gradient-border-bottom {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #a1c5ca;
    background: linear-gradient(135deg, #c1d5d8 0%,#a1c5ca 100%);
}

.two-xl-col-offset {
    @include grid-col(null null 2);
}

body {
    font-family: Circe, sans-serif;
    background-color: #fff;
}

p, h1, h2, h3, h4, h5 {
    margin: 0;
}

header {
    margin-bottom: 20px;
}

.site-heading {
    margin-bottom: 35px;
    padding-right: 40px;
    font-size: 36px;
    font-weight: 400;

    @include respond-to('sm') {
        font-size: 26px;
    }
}

.site-description {
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: 300;
}

.section {
    &-title {
        @include grid-col(12);
        margin-bottom: 5px;
        font-size: 34px;
        font-weight: 300;

        @include respond-to('sm') {
            font-size: 25px;
        }
    
        &__wrapper {
            @include grid-row();
            margin-top: 40px;
            margin-bottom: 40px;

            @include respond-to('sm') {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
    
    &-subtitle {
        @include grid-col(12);
        font-size: 16px;
    }

    &-contacts {
        @include grid-col(12);
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        p {
            position: relative;
            min-width: 30%;
            padding-left: 20px;
            line-height: 0.9;

            @include respond-to('lg') {
                min-width: 100%;
                margin-bottom: 15px;
            }

            &::before {
                content: '';
                position: absolute;
                top: -2px;
                left: 0;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        .address::before {
            background-image: url(../img/icons/event-geo.svg);
        }

        .time::before {
            background-image: url(../img/icons/event-time.svg);
        }

        .nco-name {
            text-transform: uppercase;
        }

        .nco-name::before {
            background-image: url(../img/icons/event-org.svg);
        }
    }
}

.map-heading {
    @extend %container;
}

.footer {
    @extend %container;

    &__row {
        @include grid-row();
    }

    &__copyright {
        @include grid-col(12 null 3 null 2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include respond-to('md') {
            padding: 30px 15px;
        }
    }

    &__logo {
        max-width: 105px;
        margin-bottom: 10px;
    }

    &__name {
        font-size: 22px;
        margin-bottom: 10px;

        @include respond-to('lg') {
            font-size: 20px;
        }
    }

    &__desc {
        font-size: 12px;
        text-align: center;
    }

    &__menu {
        @include grid-col(12 null 5 null 6);
        display: flex;
        margin: 0;
        padding: 0 40px 0 50px;
        font-size: 14px;

        @include respond-to('xl') {
            padding: 0;
        }

        &-wrapper {
            width: 100%;
            padding: 60px 55px;
            border-left: 2px solid #edf4f5;
            border-right: 2px solid #edf4f5;

            @include respond-to('xl') {
                padding: 60px 20px;
            }

            @include respond-to('md') {
                padding: 0 15px;
                border: none;
            }
        }

        &-links {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__link {
        width: 30%;
        margin-bottom: 20px;
        color: #000;
        font-weight: 400;
        text-decoration: none;
        transition: color .2s ease;

        @include respond-to('xl') {
            width: 31%;
        }

        @include respond-to('sm') {
            width: 40%;
        }

        &:hover {
            color: #ffad00;
        }
    }

    &__corp-data {
        font-weight: 300;
    }

    &__contacts {
        @include grid-col(12 null 4);
        padding: 60px 50px;
        font-weight: 300;

        @include respond-to('xl') {
            padding: 60px 22px;
        }

        @include respond-to('lg') {
            padding: 60px 15px;
        }

        @include respond-to('md') {
            padding: 20px 15px;
        }
    }

    &__socials {
        margin-bottom: 20px;
    }

    &__address {
        font-size: 13px;
    }
}

.privacy-policy {
    display: inline-block;
    margin-bottom: 15px;
    padding-bottom: 2px;
    font-weight: 300;
    color: #ffab00;
    text-decoration: none;
    border-bottom: 1px dotted #ffab00;
}

.header_underline {
    @extend %container;
    height: 1px;
    background-color: #c1d5d8;
}

@import "pages";