.form {
    &__title {
        margin-bottom: 25px;
        font-size: 20px;
    }

    &__label {
        display: block;
        margin-bottom: 5px;
    }

    &__input-wrapper {
        &--search {
            @include grid-col(12);
            position: relative;
            margin-bottom: 40px;
        }
    }

    &__input {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px 20px;
        border: 1px solid #b7ebef;

        &--search {
            margin: 0;
            padding: 12px 20px;
        }
    }

    &__input-group {
        display: flex;
        justify-content: space-between;

        .form__input {
            width: 47%;
        }

        input:first-child {
            margin-right: 15px;
        }
    }
}

.map-form {
    &__container {
        @extend %container;
        z-index: 1;
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        width: 360px;
        padding: 40px 35px;
        background-color: #fff;
        border: 1px solid #ceeef0;
        transform: translateY(-50%);
        z-index: 1;

        @include respond-to('sm') {
            position: relative;
            top: 0;
            width: 100%;
            margin-bottom: 20px;
            transform: none;
        }
    }

    &__more {
        display: inline-block;
        margin-bottom: 20px;
        padding-bottom: 3px;
        font-size: 14px;
        font-weight: 300;
        color: #ffab00;
        border-bottom: 1px dotted #ffab00;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;

        .btn:first-child {
            margin-right: 15px;
        }
    }
}